.active {
  color: #d5242f !important;
  font-weight: 900 !important;
  font-size: 18px;
}

/* #root {
  background-image: linear-gradient(180deg, #C8E8FF 0%, rgba(106, 170, 229, 0.83) 100%) !important;
} */

/* * {
  scroll-behavior: smooth;
} */

.navlink-stlye {
  color: #264c9e;
  text-decoration: none;
  /* padding: 11px 13px; */
  /* margin: 20px 18px 20px 0 !important; */
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 21px;
  line-height: 22px;
}

.navbar-container {
  position: fixed;
  z-index: 10;
  left: 50%;
  transform: translate(-50%);
}

.navbar {
  background: url("../src/Assets/Images/navbar-bg.svg");
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  padding: 8px;
  display: flex;
  background-repeat: no-repeat;
  /* width: 95% !important; */
}

.navbar-mobileBg {
  background: url("../src/Assets/Images/mobileNavBg.svg");
  height: 36px !important;
  /* clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%); */
  /* background-color: #fff; */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  align-items: start;
  justify-content: start;
  padding: 0px 0 0 24px;
  background-size: 100%;
}

.home-page .slick-list {
  overflow: visible !important;
}

@media only screen and (max-width: 1200px) {
  .navlink-stlye {
    /* padding: 10px 5px 10px 5px; */
  }
}

body {
  /* background-color: #09c; */
  overflow-x: hidden;
}

.w-100 {
  width: 100%;
}

.footer-img-size {
  width: 472px;
  height: 470px;
}

.inova-logo-size {
  width: 81px;
  height: 16px;
}

fieldset {
  border-width: 0 !important;
}

::placeholder {
  color: #bdbdbd;
}

.blog-size {
  width: 277.77px;
  height: 156.4px;
}

.slick-next:before {
  content: "" !important;
}

.slick-prev:before {
  content: "" !important;
}

.home-page.slick-initialized .slick-slide,
.our-product.slick-initialized .slick-slide,
.product-details.slick-initialized .slick-slide,
.about-us.slick-initialized .slick-slide,
.our-values.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* cursor: pointer !important; */
}

.our-product.slick-initialized .slick-slide {
  /* height: 95%; */
}

.product-details.slick-initialized .slick-slide {
  /* width: 100% !important; */
}

.home-page.slick-initialized .slick-slide {
  cursor: auto !important;
}

.home-page .slick-next {
  /* right: 62px !important; */
  right: -12px !important;
  /* display: none !important; */
}

.home-page .slick-prev {
  /* left: -26px !important; */
  left: -100px !important;
  /* display: none !important; */
}

.gallery-slider .slick-next {
  right: 108px !important;
  top: 580px;
  transform: scale(0.8);
}

.gallery-slider .slick-list {
  overflow: hidden !important;
}

.gallery-slider .slick-prev {
  left: 5px !important;
  top: 580px;
  transform: scale(0.8);
}

.gallery-slider .slick-track {
  top: 0px !important;
}

.product-details .slick-prev {
  left: -57px !important;
  top: 355px !important;
}

.product-details .slick-next {
  right: 45px !important;
  top: 355px !important;
}

.our-values .slick-prev {
  left: -60px !important;
  top: 330px !important;
  transform: scale(0.9);
  z-index: 1 !important;
}

.our-values .slick-next {
  right: 15px !important;
  top: 330px !important;
  transform: scale(0.9);
  z-index: 1 !important;
}

.our-values .slick-slide {
  height: auto;
}

.our-product .slick-next {
  right: 8px !important;
  top: 350px;
  /* display: none !important; */
}

.our-product .slick-prev {
  left: -90px !important;
  top: 350px;
  /* display: none !important; */
}

.home-page .slick-slide > div,
.our-product .slick-slide > div,
.product-details .slick-slide > div,
.about-us .slick-slide > div,
.our-values .slick-slide > div {
  display: grid;
  place-items: center !important;
  gap: 20px !important;
  margin-top: 50px;
  margin: auto;
  /* width: 100% !important; */
  /* height: 702px; */
}

.home-page .slick-slide > div {
  height: 705px;
}

.product-details .slick-slide > div {
  height: 750px !important;
}

.our-product .slick-slide > div {
  /* height: 1102px; */
  /* height: 720px; */
}

.our-values .slick-slide > div {
  height: 100% !important;
  /* background-color: #264c9e; */
  border-radius: 8px !important;
}

.our-values .slick-track {
  height: 600px !important;
  /* background-color: #bdbdbd; */
  display: flex;
  align-items: center;
}

.our-values .slick-slide > div {
  width: 92%;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.our-product .slick-slide > div,
.product-details .slick-slide > div {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.our-values .slick-slide > div {
  width: 85%;
}

.gallery-slider .slick-track {
  height: 700px !important;
}

.slick-track {
  top: 20px !important;
  height: 650px !important;
}
.product-details-head .slick-track{
  height: 100% !important;
}
/* .product-details .slick-track {
  height: 702px !important;
} */

.our-product .slick-track {
  top: 20px !important;
}

.sandwitches .slick-arrow {
  top: 250px !important;
}

.cups .slick-arrow {
  top: 270px !important;
}

.tubs .slick-arrow {
  top: 322px !important;
}

.cones .slick-track {
  top: 20px !important;
  height: 800px !important;
}

.sandwitches .slick-track {
  top: 20px !important;
  height: 550px !important;
}

.sticks .slick-track {
  top: 20px !important;
  height: 700px !important;
}

.cups .slick-track {
  top: 20px !important;
  height: 600px !important;
}

/* .tubs .slick-track {
  top: 20px !important;
  height: 500px !important;
} */

/* ------------------------------------ */
.about-us.slick-initialized .slick-slide {
  width: fit-content !important;
}

.about-us .slick-next {
  right: 110px !important;
  top: 110%;
}

.about-us .slick-prev {
  left: -11px !important;
  top: 110%;
}

.about-us .slick-slide > div {
  height: 440px;
}

.product-details.slick-slider,
.our-values.slick-slider {
  display: flex !important;
  justify-content: center !important;
}

.product-details .slick-list {
  width: 95% !important;
}

.our-product .slick-list {
  width: 100% !important;
}

.cones .our-product .slick-arrow {
  top: 385px !important;
}

.our-values .slick-list {
  width: 92% !important;
}

/* .slick-slider .slick-track,
.slick-slider .slick-list {
  transform:  translate3d(0px, 0px, 0px) !important;
} */

.about-us .slick-track {
  display: flex !important;
  gap: 17px !important;
  /* transform: translate3d(-243px, 0px, 0px) !important; */
}

.slick-prev,
.slick-next {
  z-index: 9 !important;
}

.slick-disabled {
  cursor: no-drop;
}

.slick-disabled {
  opacity: 0.4 !important;
}

.slider-active {
  /* width: "50%"; */
  height: 98% !important;
  transform: scale(1.6);
  /* transition: 1s; */
}

.slider-active-not {
  /* width: "50%"; */
  height: "100%";
  /* transition: 1s; */
}

.cone-size {
  width: 196px;
  height: 423px;
}

.vsc-controller {
  display: none !important;
}

.about-img-size {
  width: 364.87px;
  height: 507px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: white !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
  .css-dsm3wp-MuiFormLabel-root-MuiInputLabel-root,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1saji6j-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-1saji6j-MuiButtonBase-root-MuiMenuItem-root {
  color: #000 !important;
  background-color: white !important;
}

.css-rq16xq-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-rq16xq-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

/* about us factory img */
/* .css-0 {
  width: 91% !important;
} */

.container {
  margin: 0 auto;
  height: 1374px;
  border-radius: 0 0 85% 85%;
  overflow: hidden;
  text-align: center;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1280px) {
  .home-page .slick-next {
    right: 45px !important;
  }

  .home-page .slick-prev {
    left: -45px !important;
  }
  .our-product .slick-next {
    right: 22px !important;
    top: 350px;
    /* display: none !important; */
  }
  
  .our-product .slick-prev {
    left: -70px !important;
    top: 350px;
    /* display: none !important; */
  }
}

@media only screen and (max-width: 400px) {
  .our-values .slick-track {
    height: 100% !important;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .our-values .slick-track {
    height: 400px !important;
  }
}

@media only screen and (max-width: 600px) {
  .slick-track {
    height: 473px !important;
  }

  .home-page .slick-track {
    height: 100px !important;
  }

  .home-page .slick-slide > div {
    height: 273px !important;
  }

  .home-page .slick-prev {
    transform: scale(0.5);
  }

  .home-page .slick-next {
    transform: scale(0.5);
    right: 34px !important;
  }

  .our-values .slick-prev {
    left: -40px !important;
    top: 49% !important;
    transform: scale(0.6);
  }

  .our-values .slick-next {
    right: 15px !important;
    top: 49% !important;
    transform: scale(0.6);
  }

  .our-values .slick-list {
    width: 100% !important;
  }

  .our-values .slick-slide > div {
    width: 80%;
  }

  .our-product .slick-slide > div {
    margin-top: 170px !important;
    /* width: 100% !important; */
    height: 270px !important;
  }

  .our-product .slick-next {
    right: 8px !important;
    top: 255px !important;
    transform: scale(0.6);
    display: none !important;
  }

  .our-product .slick-prev {
    left: -55px !important;
    top: 255px !important;
    transform: scale(0.6);
  }

  .our-product .slick-list {
    width: 95% !important;
    margin-left: 13px;
  }

  .slider-active {
    /* width: "50%"; */
    transform: scale(1);
    height: 100%;
  }

  .slider-active-not {
    /* width: "50%"; */
    transform: scale(1);
    height: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  .our-values .slick-track {
    height: 320px !important;
  }

  .our-values .slick-prev,
  .our-values .slick-next {
    top: 125px !important;
    z-index: 1 !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1020px) {
  .home-page .slick-track {
    height: 100% !important;
  }

  .home-page .slick-slide > div {
    height: 100%;
  }

  .slider-active {
    /* width: "50%"; */
    transform: scale(1);
    height: 100%;
  }

  .slider-active-not {
    /* width: "50%"; */
    transform: scale(1);
    height: 100%;
  }

  .home-page .slick-next {
    top: 34% !important;
  }

  .home-page .slick-prev {
    top: 34% !important;
  }
}

/* 489px */
@media only screen and (max-width: 1251px) {
  .navbar {
    background: url("../src/Assets/Images/navbar-bg.svg");
    background-size: 100%;
    background-position: center bottom;
    align-items: center;
    justify-content: center;
    padding: 8px;
    display: flex;
    background-repeat: no-repeat;
    width: 94% !important;
  }

  .navlink-stlye {
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 387px) {
  .milk-video {
    top: 47.5% !important;
    right: 0 !important;
  }
}

@media only screen and (min-width: 388px) and (max-width: 434px) {
  .milk-video {
    top: 42% !important;
    right: 0 !important;
  }
}

/* @media only screen and (min-width: 388px) and (max-width: 434px) {
  .milk-video {
    top: 47% !important;
    right: 0 !important;
  }
} */

@media only screen and (min-width: 435px) and (max-width: 439px) {
  .milk-video {
    top: 43% !important;
    right: 0 !important;
  }
}

/* @media only screen and (min-width: 440px) and (max-width: 466px) {
  .milk-video {
    top: 40% !important;
  }
} */

@media only screen and (min-width: 440px) and (max-width: 590px) {
  .milk-video {
    top: 36% !important;
    right: 0 !important;
  }
}

@media only screen and (min-width: 509px) and (max-width: 547px) {
  .milk-video {
    top: 34% !important;
    right: 0 !important;
  }
}

@media only screen and (min-width: 548px) and (max-width: 569px) {
  .milk-video {
    top: 31% !important;
    right: 0 !important;
  }
}

@media only screen and (min-width: 570px) and (max-width: 580px) {
  .milk-video {
    top: 20% !important;
    right: 0 !important;
  }

  /* .chocolate-video {
    top: -17% !important;
  } */
}

@media only screen and (min-width: 581px) and (max-width: 590px) {
  .milk-video {
    top: 20% !important;
    right: 0 !important;
  }

  /* .chocolate-video {
    top: -17% !important;
  } */
}

@media only screen and (min-width: 591px) and (max-width: 599px) {
  .milk-video {
    top: 20% !important;
    right: 0 !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 601px) {
  .milk-video {
    top: 54% !important;
    right: 0 !important;
  }

  .chocolate-video {
    top: -8% !important;
  }
}

@media only screen and (min-width: 602px) and (max-width: 706px) {
  .milk-video {
    top: 55% !important;
    right: 0 !important;
  }

  .chocolate-video {
    top: -8% !important;
  }
}

@media only screen and (min-width: 707px) and (max-width: 780px) {
  .milk-video {
    top: 52% !important;
    right: 0 !important;
  }

  .chocolate-video {
    top: -8% !important;
    right: -138px !important;
  }
}

@media only screen and (min-width: 781px) and (max-width: 800px) {
  .milk-video {
    top: 50% !important;
    right: 0 !important;
  }

  .chocolate-video {
    top: -8% !important;
  }
}

@media only screen and (min-width: 801px) and (max-width: 850px) {
  .milk-video {
    top: 51% !important;
    right: 0 !important;
  }

  .chocolate-video {
    top: -8% !important;
  }
}

@media only screen and (min-width: 851px) and (max-width: 899px) {
  .milk-video {
    top: 52% !important;
    right: 0 !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 912px) {
  .milk-video {
    top: 53% !important;
    right: 0% !important;
  }

  .chocolate-video {
    top: -26% !important;
    right: 27% !important;
  }
}

@media only screen and (min-width: 913px) and (max-width: 920px) {
  .milk-video {
    top: 50% !important;
    right: 0% !important;
  }

  .chocolate-video {
    top: -26% !important;
  }
}

@media only screen and (min-width: 921px) and (max-width: 929px) {
  .milk-video {
    top: 49% !important;
    right: 0% !important;
  }

  .chocolate-video {
    top: -26% !important;
  }
}

@media only screen and (min-width: 930px) and (max-width: 940px) {
  .milk-video {
    top: 47.5% !important;
    right: 0% !important;
  }

  .chocolate-video {
    top: -26% !important;
  }
}

@media only screen and (min-width: 941px) and (max-width: 950px) {
  .milk-video {
    top: 46.5% !important;
    right: 0% !important;
  }

  .chocolate-video {
    top: -26% !important;
  }
}

@media only screen and (min-width: 951px) and (max-width: 960px) {
  .milk-video {
    top: 46% !important;
    right: 0% !important;
  }
}

@media only screen and (min-width: 961px) and (max-width: 981px) {
  .milk-video {
    top: 44% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 29% !important;
  }

  .chocolate-video {
    right: 30% !important;
    top: -30% !important;
  }
}

@media only screen and (min-width: 982px) and (max-width: 990px) {
  .milk-video {
    top: 42% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 29% !important;
    top: -30% !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1000px) {
  .milk-video {
    top: 41% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 29% !important;
    top: -30% !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1010px) {
  .milk-video {
    top: 39% !important;
    right: 0% !important;
  }
}

@media only screen and (min-width: 1011px) and (max-width: 1020px) {
  .milk-video {
    top: 38% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 29.7% !important;
  }
}

@media only screen and (min-width: 1021px) and (max-width: 1030px) {
  .milk-video {
    top: 37% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 29.7% !important;
  }
}

@media only screen and (min-width: 1031px) and (max-width: 1040px) {
  .milk-video {
    top: 35.5% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 29.7% !important;
  }
}

@media only screen and (min-width: 1041px) and (max-width: 1050px) {
  .milk-video {
    top: 34% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 30% !important;
  }
}

@media only screen and (min-width: 1051px) and (max-width: 1060px) {
  .milk-video {
    top: 35% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 30% !important;
  }
}

@media only screen and (min-width: 1061px) and (max-width: 1070px) {
  .milk-video {
    top: 32% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 30% !important;
  }
}

@media only screen and (min-width: 1071px) and (max-width: 1080px) {
  .milk-video {
    top: 31% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31% !important;
  }
}

@media only screen and (min-width: 1081px) and (max-width: 1090px) {
  .milk-video {
    top: 30% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31% !important;
  }
}

@media only screen and (min-width: 1091px) and (max-width: 1100px) {
  .milk-video {
    top: 28% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31% !important;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1110px) {
  .milk-video {
    top: 27% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31% !important;
  }
}

@media only screen and (min-width: 1111px) and (max-width: 1120px) {
  .milk-video {
    top: 26% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31% !important;
  }
}

@media only screen and (min-width: 1121px) and (max-width: 1130px) {
  .milk-video {
    top: 24% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31.5% !important;
  }
}

@media only screen and (min-width: 1131px) and (max-width: 1140px) {
  .milk-video {
    top: 23% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31.5% !important;
  }
}

@media only screen and (min-width: 1141px) and (max-width: 1150px) {
  .milk-video {
    top: 22% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 31.5% !important;
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1160px) {
  .milk-video {
    top: 21% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32% !important;
  }
}

@media only screen and (min-width: 1161px) and (max-width: 1170px) {
  .milk-video {
    top: 20% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32% !important;
  }
}

@media only screen and (min-width: 1171px) and (max-width: 1180px) {
  .milk-video {
    top: 19% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32% !important;
  }
}

@media only screen and (min-width: 1181px) and (max-width: 1190px) {
  .milk-video {
    top: 18% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32.5% !important;
  }
}

@media only screen and (min-width: 1191px) and (max-width: 1200px) {
  .milk-video {
    top: 17% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32.5% !important;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1210px) {
  .milk-video {
    top: 16% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32.5% !important;
  }
}

@media only screen and (min-width: 1211px) and (max-width: 1220px) {
  .milk-video {
    top: 14% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32.5% !important;
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1230px) {
  .milk-video {
    top: 13% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 32.5% !important;
  }
}

@media only screen and (min-width: 1231px) and (max-width: 1240px) {
  .milk-video {
    top: 13% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 33% !important;
  }
}

@media only screen and (min-width: 1241px) and (max-width: 1250px) {
  .milk-video {
    top: 12% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 33% !important;
  }
}

@media only screen and (min-width: 1251px) and (max-width: 1260px) {
  .milk-video {
    top: 11% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 33% !important;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1270px) {
  .milk-video {
    top: 10% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 33% !important;
  }
}

@media only screen and (min-width: 1271px) and (max-width: 1279px) {
  .milk-video {
    top: 9% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 33% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1281px) {
  .milk-video {
    top: 9% !important;
    right: 0% !important;
  }

  .chocolate-video {
    right: 36.5% !important;
  }
}

/* @media only screen and (min-width: 388px) and (max-width: 343px) {} */

/* @keyframes table-animation 
  0% {
    top: 0px;
    left: 0px;
    background: red;
  }

  25% {
    top: 0px;
    left: 100px;
    background: blue;
  }

  50% {
    top: 100px;
    left: 100px;
    background: yellow;
  }

  75% {
    top: 100px;
    left: 0px;
    background: green;
  }

  100% {
    top: 0px;
    left: 0px;
    background: red;
  }
} */

.gallery {
  opacity: 1;
  animation: mymove 2s linear;
}

@keyframes mymove {
  0% {
    opacity: 1;
    animation-delay: 1s;
  }

  50% {
    opacity: 0.5;
    animation-delay: 1s;
  }

  100% {
    opacity: 0;
    animation-delay: 1s;
  }
}

.shake:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.details a {
  text-decoration: none;
  color: #fff !important;
  /* background: #000 !important; */
  background-image: url(/src/Assets/Images/btnBg1.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 12px 33px;
  font-family: Kool Baby;
  font-size: 15px;
}

.details p {
  margin: 0 !important;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.wind {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  /* animation: wind 5s; */

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes wind {
  0% {
    transform: translate(100px) rotate(0deg);
  }

  100% {
    transform: translate(-100px) rotate(1deg);
  }
}

.MuiPaper-root.MuiAccordion-root {
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.css-tofnr2-MuiPaper-root-MuiAccordion-root:before {
  display: none !important;
}

.css-oshw89-MuiAccordionDetails-root {
  border-radius: 0 0 8px 8px;
}

.slick-active {
  transition: 3s !important;
  transition-timing-function: ease-in-out !important;
  transition-delay: 3s;
}

/* .our-product.slick-slider{
  display: flex;
} */

.gallery-slider.css-yeqvoc-MuiContainer-root {
  max-width: 100% !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
